import React, { useState, useEffect } from 'react';
import './contactus.css';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import { Helmet } from 'react-helmet';
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array

export const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCaptcha = (val) => {
    setIsCaptchaVerified(Boolean(val));
  };

  function validateEmail(email) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleSubmit = async () => {
    const sanitizedContactData = {
      name: DOMPurify.sanitize(name),
      email: DOMPurify.sanitize(email),
      phone: DOMPurify.sanitize(phone),
      message: DOMPurify.sanitize(message),
    };

    if (!name) {
      toast.warning('Name is required!');
    } else if (!email) {
      toast.warning('Email is required!');
    } else if (!validateEmail(sanitizedContactData.email)) {
      toast.warning('Invalid Email!');
      return;
    } else if (phone.length<11) {
      toast.warning('Phone number must be at least 10 digits.');
    } else if (!message) {
      toast.warning('Your message is required!');
    } else {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/form/contactus`, sanitizedContactData);
        if (res.data.status === 200) {
          toast.success('Thanks for contacting us. We will get back to you shortly.');
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
        }
      } catch (error) {
        console.error('There was an error sending the contact form!', error);
        toast.error('There was an error sending your message. Please try again later.');
      }
    }
  };
  const handlePhoneChange = (phone) => {
    const sanitizedPhone = phone.replace(/[^0-9]/g, '');
    setPhone(sanitizedPhone);
  };
  return (
    <>
      {/* Helmet for Meta Data */}
      <Helmet>
        <title>Contact K-W Property Management | Property Rental Assistance</title>
        <meta name="description" content="Get in touch with K-W Property Management for rental inquiries, landlord assistance, and tenant support in Kitchener, Waterloo, and Cambridge." />
        <meta name="keywords" content="Contact K-W Property Management, Property management support Kitchener, Property management support Waterloo, Property management support Cambridge, Landlord assistance Ontario, Tenant support services, Property rental inquiries, Real estate management contact, Leasing and rental questions, Rental property assistance, Get in touch with property managers, Kitchener-Waterloo property experts, Contact property specialists, Property maintenance requests, Rental service inquiries" />
        <link rel="canonical" href="https://www.kwproperty.com/contactus" />
        {/* Defines the schema.org context for structured data */}
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "ContactPage",
              "name": "Contact K-W Property Management",
              "description": "Get in touch with K-W Property Management for rental inquiries, landlord assistance, and tenant support in Kitchener, Waterloo, and Cambridge.",
              "url": "https://www.kwproperty.com/contactus",
              "mainEntity": {
                  "@type": "LocalBusiness",
                  "name": "K-W Property Management Corp.",
                  "url": "https://www.kwproperty.com",
                  "logo": "https://www.kwproperty.com/static/media/logo_blue.6195a1cf46880439d6fd.png", 
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "telephone": "+1-519-954-8082",
                      "contactType": "customer service",
                      "availableLanguage": ["English"],
                      "email": "kwp@kwproperty.com"
                  },
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "541 Queen Street South",
                      "addressLocality": "Kitchener",
                      "addressRegion": "ON",
                      "postalCode": "N2G-1W9",
                      "addressCountry": "CA"
                  },
                  "areaServed": ["Kitchener", "Waterloo", "Cambridge"]
              },
              "sameAs": sameAsLinks
          })}
        </script>
      </Helmet>
      <KWNavbar />
      <ToastContainer />
      <Container className='mt-5 pt-5'>
        <Row>
          <Col xs={12} sm={12} lg={8} className="order-lg-1">
            <div className='form-content'>
              <h1>Contact <span className='blue'>Us</span></h1>
              <p style={{ textAlign: 'left' }} className='pe-lg-5'>
                With <h2 className='fs-6 d-inline fw-normal'>K-W Property Management Corp.</h2>, we make finding your new home easy!
                Contact us, and we will assist you in finding not only a home but the right home for you.
                Whether you are looking for an <h3 className='fs-6 d-inline fw-normal'>apartment, condo, single dwelling home, semi-detached, townhouse,
                executive suite, or even a bachelor apartment</h3>, we have plenty of selection to choose from.
                <br /> We will help you find a home that suits the needs and amenities that best fit your lifestyle.
                <br /> If you do not see your ideal home on our website, feel free to contact us and let us know what you are looking for.
                <h4 className='mt-2 fs-6 mb-2'>Our <span className=''>Mailing Address:</span> <a href="https://www.google.com/maps/place/541+Queen+St+S,+Kitchener,+ON+N2G+1W9/@43.4437048,-80.4951079,691m/data=!3m1!1e3!4m15!1m8!3m7!1s0x882bf4f742ce64f7:0xaf1d66dfe4c07eab!2s541+Queen+St+S,+Kitchener,+ON+N2G+1W9!3b1!8m2!3d43.4437048!4d-80.4951079!16s%2Fg%2F11c284lznn!3m5!1s0x882bf4f742ce64f7:0xaf1d66dfe4c07eab!8m2!3d43.4437048!4d-80.4951079!16s%2Fg%2F11c284lznn?entry=ttu&g_ep=EgoyMDI1MDIwNS4xIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">541 Queen Street South. Kitchener, Ontario, N2G-1W9</a></h4>
                <h4 className='fs-6 mt-0 mb-2'>Phone <span className=''>Number:</span> +1 519-954-8082</h4>
                <h4 className='fs-6 mt-0 mb-2'>Email <span className=''>Address:</span> kwp@kwproperty.com</h4>
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={4} className="order-lg-2">
            <Card className='p-3 form-card mt-4 mx-auto' style={{ maxWidth: '500px' }}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Enter name"
                    maxLength={50}
                    className="form-control-sm"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter your email"
                    maxLength={100}
                    className="form-control-sm"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <PhoneInput
                    country={'ca'}
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="+1 (999) (999) (9999)"
                    inputProps={{
                      name: 'Phone',
                      required: true,
                      autoFocus: true,
                    }}
                    inputStyle={{
                      width: '100%',
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    as="textarea"
                    placeholder="Enter your message"
                    style={{ height: 'auto' }}
                    rows={4}
                    maxLength={500}
                    className="form-control-sm"
                  />
                </Form.Group>
                <div className="mt-2 mb-1" style={{ fontSize: "14px", wordSpacing: "1px" }}>
                  To protect this website from spam, please check the box below.
                </div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleCaptcha}
                />
                <Button
                  onClick={isCaptchaVerified ? handleSubmit : () => toast.warning('Please verify you are not a robot.')}
                  className='btn btn-primary mt-2 w-100'
                >
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <KWFooter />
    </>
  );
};