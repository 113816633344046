import React, { useState, useEffect } from 'react';
import './Landlords.css';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array

const InputField = ({ label, name, type, handleChange,required=true,placeholder='' }) => (
  <div className="landlords-input mt-4">
    <label htmlFor={name} className='landlords-form-label'>{label} {required&&<span className="text-danger">*</span>}</label>
    <input placeholder={placeholder} type={type} name={name} id={name} onChange={handleChange} className='required-number' />
  </div>
);

const SelectField = ({ label, name, options, handleChange }) => (
  <div className="landlords-input mt-4">
    <label htmlFor={name} className='landlords-form-label'>{label} <span className="text-danger">*</span></label>
    <select name={name} id={name} onChange={handleChange} className='landlords-form-select'>
      <option value="">----select an option----</option>
      {options.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))}
    </select>
  </div>
);

export const Landlords = () => {
  const [moreInfo, setMoreInfo] = useState({
    BestTimeToCall: "",
    City: "",
    EmailAddress: "",
    FirstName: "",
    HowManyRental: "",
    LastName: "",
    Phone: "",
    PostalCode: "",
    PropertyCurrently: "",
    PropertyType: "",
    Province: "",
    StreetAddress: "",
    optradio: "",
  });

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMoreInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePostalCode = (postalCode) => {
    // Canadian Postal Code Validation: A1A 1A1 format
    const caRegex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/;
    return caRegex.test(postalCode);
  };

  const handleValidation = () => {
    let isValid = true;

    if (!moreInfo.FirstName) {
      toast.error("First Name is required.");
      isValid = false;
    }
    if (!moreInfo.LastName) {
      toast.error("Last Name is required.");
      isValid = false;
    }
    if (!moreInfo.EmailAddress) {
      toast.error("Email Address is required.");
      isValid = false;
    } else if (!validateEmail(moreInfo.EmailAddress)) {
      toast.error("Invalid email address.");
      isValid = false;
    }

    if (moreInfo.Phone.length < 11) {

      toast.error("Phone number should be atleast 10 digit.");
      isValid = false;
    }
    if (moreInfo.PostalCode) {
      if (!validatePostalCode(moreInfo.PostalCode)) {
        toast.error("Invalid Canadian postal code.");
        isValid = false;
      }
    } 
    if (!moreInfo.PropertyType) {
      toast.error("Property Type is required.");
      isValid = false;
    }
    if (!moreInfo.BestTimeToCall) {
      toast.error("Best Time to Call is required.");
      isValid = false;
    }
    if (!moreInfo.HowManyRental) {
      toast.error("Please select how many rental properties you own.");
      isValid = false;
    }

    if (!isCaptchaVerified) {
      toast.error("Please complete the CAPTCHA.");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!handleValidation()) return;

    const sanitizedData = Object.keys(moreInfo).reduce((acc, key) => {
      acc[key] = DOMPurify.sanitize(moreInfo[key]);
      return acc;
    }, {});

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/form/moreinfo`, sanitizedData);
      if (response.data.status === 200) {
        toast.success('Form submitted successfully! Redirecting...');
        setTimeout(() => {
          window.location.href = '/thankyou';
        }, 2000);
      } else {
        toast.error(response.data.res || 'Failed to submit the form. Please try again.');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.res || 'An unexpected error occurred.';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePhoneChange = (phone) => {
    const sanitizedPhone = phone.replace(/[^0-9]/g, '');
    setMoreInfo((prev) => ({
      ...prev,
      Phone: sanitizedPhone
    }));
  };

  return (
    <>
      <Helmet>
        <title>Rental Property Management | Trusted Landlord Solutions</title>
        <meta name="description" content="Maximize your rental income with expert property management in Kitchener, Waterloo, and Cambridge. Tenant placement, rent collection & maintenance." />
        <meta name="keywords" content="Landlord property management solutions, Rental property services Kitchener, Rental property services Waterloo, Rental property services Cambridge, Professional property management Ontario, Tenant placement services, Hassle-free landlord support, Rent collection and leasing services, Real estate investment management, Maintenance and property care, Trusted rental managers Kitchener-Waterloo, Reliable landlord services, Income property management, Residential property management, Property leasing and tenant screening" />
        <link rel="canonical" href="https://www.kwproperty.com/Landlords" />
        {/* JSON-LD structured data for SEO: Defines business details for a real estate agent. */}
        <script type="application/ld+json">
        {
          JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "@id": "https://www.kwproperty.com/#landlords",
              "name": "KW Property Management - Landlord Services",
              "description": "Maximize your rental income with expert property management services in Kitchener, Waterloo, and Cambridge. We offer tenant placement, rent collection, property maintenance, and more.",
              "image": "https://www.kwproperty.com/static/media/logo_blue.6195a1cf46880439d6fd.png",
              "url": "https://www.kwproperty.com/Landlords",
              "telephone": "+1-519-954-8082",
              "email": "kwp@kwproperty.com",
              "priceRange": "$1000+",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "541 Queen Street South",
                "addressLocality": "Kitchener",
                "addressRegion": "ON",
                "postalCode": "N2G-1W9",
                "addressCountry": "CA"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 43.4437114,
                "longitude": -80.4950913
              },
              "serviceArea": {
                "@type": "AdministrativeArea",
                "name": "Kitchener, Waterloo, Cambridge, Guelph, Brantford, London, Hamilton, Mississauga, Toronto"
              },
              "openingHours": "Mo-Fr 09:00-17:00",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-519-954-8082",
                "contactType": "customer service",
                "availableLanguage": ["English"]
              },
              "sameAs": sameAsLinks
            }           
          )
        }
        </script>
      </Helmet>
      <KWNavbar />
      <ToastContainer />
      <div className="container mt-5 pt-5 mb-5">
        <div className="col-sm-12 col-md-11 mx-auto">
          <div className="landlords-main-head">
            <h1 className="landlords-cHeader-h1 mb-0">Property Owners - Learn More About Us!</h1>
            <h4 className="landlords-cPara-h4 lh-base ">
              If you’re a property owner interested in discovering more about our property management services, simply fill out the form below. We’ll provide you with detailed information tailored to your needs. You can also explore our
              <h5 className='landlords-cPara-h5 d-inline'><Link to={'/FAQ'}>Frequently Asked Questions</Link></h5>, or feel free to reach out to us directly with any additional questions or comments:
            </h4>
            <div className="landlords-cContacth">
              <h2 className="landlords-cContacth1-h2 m-0">Phone: +1 519-954-8082</h2>
              <h3 className="landlords-cContacth2-h3">Email: <a href="mailto:kwp@kwproperty.com">kwp@kwproperty.com</a></h3>
            </div>
          </div>

          <div className="landlord-form-main">
            <div className="landlords-form">
              <InputField label="First Name" name="FirstName" type="text" handleChange={handleChange} />
              <InputField label="Last Name" name="LastName" type="text" handleChange={handleChange} />
              <InputField label="Email Address" name="EmailAddress" type="text" handleChange={handleChange} />
              <div className='landlords-input mt-4'>
                <label className='landlords-form-label'>Phone
                  <span class="text-danger">*</span>

                </label>
                <PhoneInput
                  country={'ca'}
                  value={moreInfo.Phone}
                  onChange={handlePhoneChange}
                  placeholder="+1 (999) (999) (9999)"
                  inputProps={{
                    name: 'Phone',
                    required: true,
                    autoFocus: true,
                  }}
                  inputStyle={{
                    width: '100%',
                  }}
                />
              </div>
              <InputField placeholder=' Optional' required={false} label="Street Address" name="StreetAddress" type="text" handleChange={handleChange} />
              <InputField placeholder=' Optional' required={false} label="City" name="City" type="text" handleChange={handleChange} />
              <InputField placeholder=' Optional' required={false} label="Province" name="Province" type="text" handleChange={handleChange} />
              <InputField placeholder=' Optional' required={false} label="Postal Code" name="PostalCode" type="text" handleChange={handleChange} />

              <SelectField
                label="Property Type"
                name="PropertyType"
                options={["Apartment", "Commercial", "House", "Townhouse", "Semi-Detached"]}
                handleChange={handleChange}
              />

              <SelectField
                label="Best Time To Call"
                name="BestTimeToCall"
                options={["Anytime", "Morning", "Afternoon", "Evening"]}
                handleChange={handleChange}
              />

              <SelectField
                label="How Many Rental Properties Do You Own?"
                name="HowManyRental"
                options={["1", "2", "3", "4", "5", "More than 5"]}
                handleChange={handleChange}
              />

              <div className="landlords-input mt-4">
                <div className='landlords-form-label2'>Basic property details & any questions / comments <span className="text-danger">*</span></div>
                <textarea onChange={handleChange} name="PropertyCurrently" id="Property-Currently-textarea" className='required-number required-number2 mt-2 Property-Currently-textarea'></textarea>
              </div>

              <div className='capthaBox-container'>
                <div className="mt-4 capthaBox">
                  <div className='captchaText'>
                    To protect this website from spam, please check the box below.
                  </div>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={() => setIsCaptchaVerified(true)}
                    onExpired={() => setIsCaptchaVerified(false)}

                  />
                  <div className="mt-4 d-flex gap-2">
                    <button onClick={() => window.history.back()} type='button' className='btn btn-secondary' disabled={isSubmitting}>
                      Back
                    </button>
                    <button onClick={handleSubmit} type='button' className='Property-Currently-button' disabled={isSubmitting}>
                      {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <KWFooter />
    </>
  );
};