import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from "react-icons/io";
import './ServiceRequest.css';

const ServiceRequestForm = ({
    serviceCategory,
    subCategory,
    serviceReqData,
    setServiceReqData,
    countFile,
    handleDocumentUploads,
    removeFile,
    setsubCategory,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    isForThisServiceCallChecked,
    setisForThisServiceCallChecked,
    isIacknowledgeAndChecked,
    IacknowledgeAndChanged
}) => {
    const [errors, setErrors] = useState({});

    const sanitizeInput = (input) => {
        // Remove any HTML tags to prevent XSS attacks
        return input.replace(/<[^>]*>?/gm, '');
    };

    const validateForm = () => {
        let formErrors = {};
        if (!serviceReqData.CategoryId) {
            formErrors.CategoryId = 'Service Category is required.';
        }
        if (!serviceReqData.SubCategoryId && subCategory?.length > 0) {
            formErrors.SubCategoryId = 'Service Sub-category is required.';
        }
        if (!serviceReqData.RequestSummary) {
            formErrors.RequestSummary = 'Description is required.';
        } else if (serviceReqData.RequestSummary.length < 10) {
            formErrors.RequestSummary = 'Description should be at least 10 characters.';
        }
        if (!serviceReqData.Priority) {
            formErrors.Priority = 'Priority is required.';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleInputChange = (name, value) => {
        value = sanitizeInput(value);
        setServiceReqData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted:', serviceReqData);
            // Handle form submission logic here
        } else {
            console.log('Form has errors:', errors);
        }
    };

    return (
        <div className='border rounded p-3 mt-3'>
            <form className="service-form" onSubmit={handleSubmit}>
                <div className='fs-4 justify-content-between d-flex align-items-center' data-bs-toggle="collapse" data-bs-target="#Servicerequired" style={{ cursor: "pointer" }}>
                    <h3 className='fs-4 m-0 d-inline'><b>Work / Service required</b></h3>
                    <div className="">
                        <img style={{ width: "15px" }} src="https://cdn-icons-png.flaticon.com/512/25/25629.png" alt="Location icon" />
                    </div>
                </div>
                <div className="collapse show" id="Servicerequired">
                    <div className='mt-2 input-div'>
                        <div className='service-form-label2'>Service Category</div>
                        <select
                            onChange={(e) => {
                                const value = e.target.value;
                                const cat = serviceCategory.category.filter(r => r.CategoryId === value);
                                setsubCategory(cat);
                                handleInputChange('CategoryId', value);
                                handleInputChange('Category', cat[0]?.CategoryName || '');
                            }}
                            className='form-control'
                            value={serviceReqData.CategoryId || ''}
                        >
                            <option value="">------ Select category ------</option>
                            {serviceCategory.category.map(data => (
                                <option key={data.CategoryId} value={data.CategoryId}>{data.CategoryName}</option>
                            ))}
                        </select>
                        {errors.CategoryId && <span className="error-text">{errors.CategoryId}</span>}
                    </div>
                    {subCategory?.length > 0 && (
                        <div className='mt-2 input-div'>
                            <div className='service-form-label2'>Service Sub-category</div>
                            <select
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const subCat = subCategory[0]?.SubCategories?.filter(d => d.SubCategoryId === value);
                                    handleInputChange('SubCategoryId', value);
                                    handleInputChange('SubCategory', subCat[0]?.SubCategoryName || '');
                                }}
                                className='form-control'
                                value={serviceReqData.SubCategoryId || ''}
                            >
                                <option value="">------ Select sub-category ------</option>
                                {subCategory[0]?.SubCategories?.map(data => (
                                    <option key={data.SubCategoryId} value={data.SubCategoryId}>{data.SubCategoryName}</option>
                                ))}
                            </select>
                            {errors.SubCategoryId && <span className="error-text">{errors.SubCategoryId}</span>}
                        </div>
                    )}
                    <div className="mt-3">
                        <div className='mb-2 mt-2'><b>Please describe the work or service required:</b></div>
                        <div className="PleaseDescribe my-1">
                            If you're dealing with an <b style={{ color: "#0c5460" }}>appliance issue</b>, please specify the <b style={{ color: "#0c5460" }}>make</b> and <b style={{ color: "#0c5460" }}>model</b> so we can better serve you.
                        </div>
                        <textarea
                            onChange={(e) => handleInputChange('RequestSummary', e.target.value)}
                            value={serviceReqData.RequestSummary || ''}
                            className='service-form-input2 required-number required-number2'
                            rows="3"
                            maxLength="250"
                        />
                        <small style={{ opacity: 0.6 }}>{250 - (serviceReqData.RequestSummary?.length || 0)} characters remaining</small>
                        {errors.RequestSummary && <span className="error-text">{errors.RequestSummary}</span>}
                    </div>
                    <div className="input-div mt-3">
                        <div className='service-form-label2'>Priority:</div>
                        <select
                            onChange={(e) => handleInputChange('Priority', e.target.value)}
                            className='form-control'
                            value={serviceReqData.Priority || ''}
                        >
                            <option value="">------ Select priority ------</option>
                            <option value="Urgent">Urgent</option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                        </select>
                        {errors.Priority && <span className="error-text">{errors.Priority}</span>}
                    </div>
                    <div className="mt-3">
                        <Form>
                            <div>Do you need to be home for this service call?</div>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label=''
                                className='mt-1'
                                checked={isForThisServiceCallChecked}
                                onChange={() => setisForThisServiceCallChecked(!isForThisServiceCallChecked)}
                            />
                        </Form>
                        {
                            <div className='YesNoDropdown'>
                                <div className='YesNoButton'>{isForThisServiceCallChecked ? 'yes' : 'no'}</div>
                            </div>
                        }
                        {!isForThisServiceCallChecked && (
                            <div className='alert alert-info mt-2 d-flex align-items-center'>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="acknowledge-switch"
                                        checked={isIacknowledgeAndChecked}
                                        onChange={IacknowledgeAndChanged}
                                        className='serviceCall_check'
                                        label="I acknowledge and allow a tradesperson to enter the premises to carry out the repairs required."
                                    />
                                </Form>
                            </div>
                        )}
                    </div>
                    <div style={{position:'relative'}} className="form-group mt-3">
                        <div className="mb-2 fw-bold fs-5">Have any photos to show us?</div>
                        <div
                            className="custom-file file-upload-container"
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <input
                                id="file-upload"
                                className="custom-file-input"
                                type="file"
                                onChange={handleDocumentUploads}
                                multiple
                                accept="image/gif,image/png,image/jpeg,application/pdf"
                            />
                            <label className="custom-file-label" htmlFor="file-upload">
                                Drag & Drop your files or <span className="filepond--label-action">Browse</span>
                            </label>
                        </div>
                        {countFile.length > 0 && countFile.map((f, index) => (
                            <div key={index} className='w-100 h-auto d-flex justify-content-between p-2 border my-2 rounded'>
                                <h6 className="name">{f.name}</h6>
                                <div className='cursor-pointer'>
                                    <IoIosCloseCircleOutline onClick={() => removeFile(index)} size={30} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ServiceRequestForm;
