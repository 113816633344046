import React from 'react';
import { Collapse } from 'react-bootstrap';

const ImpInfo = ({ visible, toggle }) => {
    return (
        <div>
            <div className="justify-content-between d-flex" style={{ cursor: "pointer" }} onClick={toggle}>
                <h2 className="TenentTenancy-header2 m-0">Important Information from the Landlord and Tenant Board</h2>
                <div>
                    <div className='fs-2 fw-medium lh-sm my-3' style={{ cursor: "pointer" }}>{visible ? "−" : "+"}</div>
                </div>
            </div>
            <Collapse in={visible}>
            <div className="px-3 pt-2 pb-4">
                                    <div className="TenentTenancy-Box-grid">
                                        <h3 className="TenentTenancy-Box-grid1 d-inline lh-base">The termination date</h3>
                                        <div className="TenentTenancy-Box-grid2">
                                            <div className="">
                                                <h4 className='d-inline fs-6 fw-normal'>
                                                For most types of tenancies (including monthly tenancies) the termination date must be at least <b className="text-dark">60 days</b> after the tenant gives the landlord this notice. Also, the termination date must be the last day of the rental period. For example, if the tenant pays on the first day of each month, the termination date must be the last day of the month. If the tenancy is for a fixed term (for example, a lease for one year), the termination date cannot be earlier than the last date of the fixed term.
                                                </h4>
                                            </div>
                                            <div className="mt-1">
                                                <b className='text-dark'>Exceptions:</b> <br />
                                                <div className=''>
                                                    A landlord can require a tenant to sign an N9 Tenant's Notice to End the Tenancy as a condition of agreeing to rent a rental unit in the following two situations:
                                                </div>
                                                <ul className="">
                                                    <li>
                                                        <h4 className='d-inline fw-normal fs-6'>The termination date must at least <b>28 days</b> after the tenant gives the landlord this notice if the tenancy is daily or weekly (the tenant pays rent daily or weekly).</h4> Also, the termination date must be the last day of the rental period. For example, if the tenant pays rent weekly each Monday, the termination date must be a Sunday. If the tenancy is for a fixed term, the termination date cannot be earlier than the last date of the fixed term.
                                                    </li>
                                                    <li>
                                                        The termination date can be earlier than the last day of a fixed term tenancy (but still must be the last day of a rental period) if the tenant is giving this notice because:
                                                        <br />
                                                        <ul className="">
                                                            <li>the tenancy agreement was entered into on or after April 30, 2018,</li>
                                                            <li>the landlord was required to use the Residential Tenancy Agreement (Standard Form of Lease) form but did not,</li>
                                                            <li>the tenant demanded in writing that the landlord give them this form, and
                                                                <ul className="">
                                                                    <li>more than 21 days have passed since the tenant made their demand, and the landlord has not provided the form,</li>
                                                                    <b>OR</b>
                                                                    <li>the landlord provided the form less than 30 days ago but it was not signed by the tenant.</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <h4 className='d-inline fs-6 fw-normal'>
                                                        A special rule allows <b className="text-dark">less than 60 days' notice</b> in situations where the tenant would normally be required to give 60 days notice (for example, monthly tenancies). The tenant can give notice for the end of February no later than January 1st and can give notice for the end of March no later than February 1st.
                                                        </h4>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <h3 className="TenentTenancy-Box-grid1 d-inline lh-base">The landlord can apply to end the tenancy</h3>
                                        <div className="TenentTenancy-Box-grid2">
                                            The landlord can apply to the Board for an order to end the tenancy and evict the tenant as soon as the tenant gives the landlord this notice. However, if the Board issues an order ending the tenancy, the order will not require the tenant to move-out any earlier than the termination date the tenant included in this notice.
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <h3 className="TenentTenancy-Box-grid1 d-inline lh-base">When a tenant can give 10 days' notice</h3>
                                        <div className="TenentTenancy-Box-grid2">
                                            The termination date set out in this notice can be <b className="text-dark">10 days</b> (or more) after the tenant gives this notice to the landlord if the landlord has given the tenant either an <h4 className='d-inline fs-6 fw-normal'><b className="text-dark">N12 Notice to End your Tenancy or an N13 Notice to End your Tenancy.</b></h4> The termination date does not have to be the last day of a rental period.
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <h3 className="TenentTenancy-Box-grid1 d-inline lh-base">Ending the tenancy when the landlord refused to allow the tenant to assign the rental unit</h3>
                                        <div className="TenentTenancy-Box-grid2">
                                            <div>
                                                The tenant can use this notice to end the tenancy if the tenant asked the landlord for permission to assign the rental unit to someone else, and the landlord refused. The termination date must be:
                                            </div>
                                            <ul>
                                                <li><h4 className='d-inline fs-6 fw-normal'>at least <b className='text-dark'>28 days</b> after the tenant gives the notice to the landlord if the tenancy is daily or weekly,</h4></li>
                                                <li><h4 className='d-inline fs-6 fw-normal'>at least <b className='text-dark'>30 days</b> after the tenant gives the notice to landlord if the tenancy is anything other than daily or weekly.</h4></li>
                                            </ul>
                                            <div className='mt-1'>The termination date does not have to be the last day of a rental period or the last day of a fixed term.</div>
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <h3 className="TenentTenancy-Box-grid1 d-inline lh-base">Ending the tenancy in a care home</h3>
                                        <div className="TenentTenancy-Box-grid2">
                                            <div className="">If the tenant lives in a care home, the termination date in this notice can be 30 days (or more) after the tenant gives the notice to the landlord. The termination date does not have to be the end of a rental period or the last day of a fixed term.</div>
                                            <div className="mt-2">
                                                If a tenant who lives in a care home gives this notice to the landlord, they can also give the landlord a 10-day notice for the landlord to stop providing care services and meals. If the tenant gives the landlord the 10-day notice, the tenant is not required to pay for care services and meals after the end of the 10-day period.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <h3 className="TenentTenancy-Box-grid1 d-inline lh-base">Tenants can't be required to sign this notice</h3>
                                        <div className="TenentTenancy-Box-grid2">
                                            <div>
                                                A landlord cannot require the tenant to sign an N9 Tenant's Notice to End the Tenancy as a condition of agreeing to rent a unit. A tenant does not have to move-out based on this notice if the landlord required the tenant to sign it when the tenant agreed to rent the unit.
                                            </div>
                                            <div className="mt-2">
                                                <b className="text-dark">Exceptions:</b> <br />
                                                <div>A landlord can require a tenant to sign an N9 Tenant's Notice to End the Tenancy as a condition of agreeing to rent a rental unit in the following two situations:</div>
                                                <ul className='mt-1'>
                                                    <li>The tenant is a student living in accommodation provided by a post-secondary institution or by a landlord who has an agreement with the post-secondary school to provide the accommodation.</li>
                                                    <li>
                                                        The tenant is occupying a rental unit in a care home for the purposes of receiving rehabilitative or therapeutic services, and
                                                        <ul>
                                                            <li>the tenant agreed to occupy the rental unit for not more than 4 years,</li>
                                                            <li>the tenancy agreement set out that the tenant can be evicted when the objectives of providing the care services have been met or will not be met, and</li>
                                                            <li>the rental unit is provided to the tenant under an agreement between the landlord and a service manager under the Housing Services Act, 2011</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TenentTenancy-Box-grid mt-2'>
                                        <h3 className="TenentTenancy-Box-grid1 d-inline lh-base">The tenant must move-out by the termination date</h3>
                                        <div className="TenentTenancy-Box-grid2">
                                            The tenant must move-out and remove all their personal possessions from the rental unit by the termination date set out on page 1. If the tenant moves out by the termination date set out above, but leaves behind personal possessions, the tenant will no longer have any rights to those possessions and the landlord will be allowed to dispose of them.
                                        </div>
                                    </div>
                                    <div className="TenentTenancy-Box-grid mt-2">
                                        <h3 className="TenentTenancy-Box-grid1 d-inline lh-base">How to get more information</h3>
                                        <div className="TenentTenancy-Box-grid2">
                                            For more information about this notice or your rights, you can contact the Landlord and Tenant Board. You can reach the Board by phone at <h4 className='d-inline fs-6'><b className="text-dark">416-645-8080</b></h4> or <h4 className='d-inline fs-6'><b className="text-dark">1-888-332-3234</b></h4>. You can visit the Board's website at <h4 className='d-inline fw-normal fs-6'><a href="" className='TenentTenancy-text-link'>tribunalsontario.ca/ltb</a></h4>.
                                        </div>
                                    </div>
                                </div>
            </Collapse>
        </div>
    );
};

export default ImpInfo;
