import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import './SubletAgreement.css'
import pdf from '../../assets/subletAgreement5054218d86477fe06eb3-2.pdf';
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array


export const SubletAgreement = () => {
    return (
        <>
            <div className='SubletAgreementMainDiv'>
                <Helmet>
                    <title>Sublet Agreement | Rental Guidelines & Policies</title>
                    <meta
                        name="description"
                        content="Understand subletting rules with K-W Property Management. Learn tenant responsibilities, legal considerations & sublet policies in Kitchener-Waterloo."
                    />
                    <meta
                        name="keywords"
                        content="Sublet agreement Kitchener, Sublet agreement Waterloo, Sublet agreement Cambridge, Subletting policies Ontario, Rental sublease rules, Tenant sublet guidelines, Apartment subleasing Ontario, Sublet rental agreements, Landlord approval for subletting, Subleasing rights and responsibilities, Temporary rental agreements, Legal sublease process, Renting out your lease, Sublease contract terms, Residential sublet guidelines"
                    />
                    <link rel="canonical" href="https://www.kwproperty.com/SubletAgreement" />
                    {/* JSON-LD structured data for SEO: Defines business details for a real estate agent. */}
                    <script type="application/ld+json">
                        {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Sublet Agreement | Rental Guidelines & Policies",
                        "description": "Understand subletting rules with K-W Property Management. Learn tenant responsibilities, legal considerations & sublet policies in Kitchener-Waterloo.",
                        "url": "https://www.kwproperty.com/SubletAgreement",
                        "breadcrumb": {
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home",
                                "item": "https://www.kwproperty.com/"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Sublet Agreement",
                                "item": "https://www.kwproperty.com/SubletAgreement"
                            }
                            ]
                        },
                        "provider": {
                            "@type": "Organization",
                            "name": "KW Property Management",
                            "url": "https://www.kwproperty.com/",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+1-519-954-8082",
                                "contactType": "customer service",
                                "email": "kwp@kwproperty.com"
                            }
                        },
                        "keywords": [
                            "Sublet agreement Kitchener",
                            "Sublet agreement Waterloo",
                            "Sublet agreement Cambridge",
                            "Subletting policies Ontario",
                            "Rental sublease rules",
                            "Tenant sublet guidelines",
                            "Apartment subleasing Ontario",
                            "Sublet rental agreements",
                            "Landlord approval for subletting",
                            "Subleasing rights and responsibilities",
                            "Temporary rental agreements",
                            "Legal sublease process",
                            "Renting out your lease",
                            "Sublease contract terms",
                            "Residential sublet guidelines"
                        ],
                        "mainEntity": {
                            "@type": "Article",
                            "headline": "Sublet Agreement | Rental Guidelines & Policies",
                            "description": "A comprehensive guide to subletting policies, tenant responsibilities, and legal considerations in Kitchener-Waterloo.",
                            "author": {
                                "@type": "Organization",
                                "name": "KW Property Management"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "KW Property Management",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://www.kwproperty.com/static/media/logo_blue.6195a1cf46880439d6fd.png"
                                }
                            },
                            "datePublished": "2024-03-08",
                            "dateModified": "2024-03-08",
                            "hasPart": {
                                "@type": "MediaObject",
                                "contentUrl": "https://www.kwproperty.com/static/media/subletAgreement5054218d86477fe06eb3-2.5054218d86477fe06eb3.pdf",
                                "encodingFormat": "application/pdf",
                                "name": "Download Sublet Agreement PDF"
                            }
                        },
                        "sameAs": sameAsLinks
                        })}
                    </script>
                </Helmet>
                <KWNavbar></KWNavbar>
                <div className="container mt-5 mb-5 pt-5">
                    <div className="mt-5 mb-4  SubletAgreementMain">
                        <h1 className='text-center h3'>SUBLET AGREEMENT</h1>

                        <div className="SubletAgreementContent mt-5">
                            <div className='SubletAgreementPara1'>This agreement made this <input disabled type="text" name="text" id="text" className='SubletAgreementPara1Input bg-white' /> day of <input disabled type="text" name="" id="" className='SubletAgreementPara1Input bg-white' />, 20 <input disabled type="text" name="" id="" className='SubletAgreementPara1Input bg-white' /> between
                            </div>
                            <div className='SubletAgreementPara2 mt-4'><input disabled type="text" name="text" id="text" className='SubletAgreementPara2Input bg-white' />
                                <div className='SubletAgreementSubPara2'>(Hereinafter called the “Tenant(s)” of the first part)
                                </div>
                            </div>
                            <div className='SubletAgreementPara3 mt-4'>And <input disabled type="text" name="text" id="text" className='SubletAgreementPara3Input bg-white' />
                                <div className='SubletAgreementSubPara3'>(Hereinafter called the “Sub-tenant(s)” of the second part)
                                </div>
                            </div>
                            <div className='SubletAgreementPara4 mt-4'>Whereas the Tenant(s) are signatories to a certain Tenancy Agreement dated this <input disabled type="text" name="text" id="text" className='SubletAgreementPara4Input bg-white' /> day
                                of <input disabled type="text" name="text" id="text" className='SubletAgreementPara4Input bg-white' />, 20 <input disabled type="text" name="text" id="text" className='SubletAgreementPara4Input bg-white' /> between
                            </div>
                            <div className='SubletAgreementPara5 mt-4'><input disabled type="text" name="text" id="text" className='SubletAgreementPara5Input bg-white' />
                                <h2 className='SubletAgreementSubPara5 fs-6 fw-normal m-0'>(Tenants) </h2>
                            </div>
                            <div className='SubletAgreementPara6 mt-4'>And <br /><input disabled type="text" name="text" id="text" className='SubletAgreementPara6Input bg-white' />
                                <h2 className='SubletAgreementSubPara6 fs-6 fw-normal m-0'>(Landlord)  </h2>
                            </div>
                            <div className='SubletAgreementPara7 mt-4'>
                                And whereas the Tenant(s) are desirous of subletting their premises and whereas the
                                subtenants are desirous of occupying the premises now currently rented by the tenants,
                                municipally knows as <input type="text" disabled name="text" id="text" className='SubletAgreementPara7Input bg-white' /> <h3 className='fs-6 d-inline fw-normal'>Waterloo,
                                Ontario</h3> and the parties hereto agree as follows:

                            </div>
                            <div className='SubletAgreementPara8 mt-4'>
                                1. The Sub-tenant(s) tenancy shall commence on <input disabled type="text" name="text" id="text" className='SubletAgreementPara8Input bg-white' /> 20 <input disabled type="text" name="" id="" className='SubletAgreementPara8Input bg-white' /> and end on
                                <input disabled type="text" name="text" id="text" className='SubletAgreementPara8Input bg-white' /> 20 <input disabled type="text" name="text" id="text" className='SubletAgreementPara8Input bg-white' />.

                            </div>
                            <div className='SubletAgreementPara9 mt-4'>
                                2. The Tenant(s) may direct the Sub-tenant(s) to pay the aforementioned rent to the Tenant(s),
                                who will pay the rent to the Landlord. The Tenant(s) may also direct the Sub-tenant(s) to pay the
                                aforementioned rent directly to the Landlord.

                            </div>
                            <div className='SubletAgreementPara10 mt-4'>
                                3. The Sub-tenant(s) shall pay the Tenant(s) the sum of $ <input disabled type="text" name="text" id="text" className='SubletAgreementPara10Input bg-white' /> per month for the period of
                            </div>
                            <div className='SubletAgreementPara11'>
                                <div className='w-100'>
                                    <input disabled type="text" name="text" id="text" className='SubletAgreementPara11Input bg-white' />
                                    <div>Month / Day / Year </div>
                                </div>
                                to
                                <div className='w-100'>
                                    <input disabled type="text" name="text" id="text" className='SubletAgreementPara11Input bg-white' />
                                    <div>Month / Day / Year </div>
                                </div>

                            </div>
                            <div className='SubletAgreementPara12 mt-4'>
                                4. The Sub-tenant(s) shall abide by all terms and conditions in the Tenancy Agreement dated
                                <input disabled type="text" name="text" id="text" className='SubletAgreementPara12Input bg-white' /> 20 <input disabled type="text" name="text" id="text" className='SubletAgreementPara12Input bg-white' />. As if they were signatories thereto.

                            </div>
                            <div className='SubletAgreementPara13 mt-4'>
                                5. The Sub-tenant(s) shall be bound by all of the terms and conditions in the aforementioned
                                <h4 className='d-inline fs-6 fw-normal'> Tenancy Agreement</h4>.

                            </div>
                            <div className='SubletAgreementPara13 mt-4'>
                                6. The Sub-tenant(s) and Tenant(s) agree that all terms and conditions existing in the <h4 className='d-inline fs-6 fw-normal'>Tenancy
                                Agreement</h4> dated <input disabled type="text" name="text" id="text" className='SubletAgreementPara13Input bg-white' /> shall govern the relationship between the
                                Tenant(s) and the Sub-tenant(s). The Tenants and Sub-tenants agree that the Sub-tenant(s)
                                shall be bound by all of the terms and conditions in the aforementioned Tenancy.

                            </div>
                            <div className='SubletAgreementPara13 mt-4'>
                                7. The Tenant(s) are responsible for the lease and all items pertaining to it. This includes any
                                and all damages caused to the <h5 className='d-inline fs-6 fw-normal'>rental unit, rent amount, parking payments (if applicable),
                                maintaining tenant insurance in good standing, etc</h5>.

                            </div>
                            <div className='SubletAgreementPara14 mt-4'>
                                This agreement signed this <input disabled type="text" name="text" id="text" className='SubletAgreementPara14Input bg-white' /> day of <input type="text" className='SubletAgreementPara14Input bg-white' disabled /> , 20 <input disabled type="text" name="" id="" className='SubletAgreementPara14Input bg-white' />.

                            </div>
                            <div className='SubletAgreementPara15 mt-4'><input disabled type="text" name="text" id="text" className='SubletAgreementPara15Input bg-white' />
                                <div className='SubletAgreementSubPara15'>
                                    Sub-Tenant
                                </div>
                            </div>
                            <div className='SubletAgreementPara16 mt-4'><input disabled type="text" name="text" id="text" className='SubletAgreementPara16Input bg-white' />
                                <div className='SubletAgreementSubPara16'>
                                    Tenant(s)
                                </div>
                            </div>
                            <div className='SubletAgreementPara17 mt-4'>
                                Sub-Tenant Phone Number : <input disabled type="text" name="text" id="text" className='SubletAgreementPara17Input bg-white' />
                            </div>
                            <div className='SubletAgreementPara18 mt-4'>
                                Sub-Tenant Email Address : <input disabled type="text" name="text" id="text" className='SubletAgreementPara18Input bg-white' />

                            </div>
                            <div className="mt-4 pt-2">
                                <Button className='btn btn-primary float-end px-2 py-1 downloadbtn' href={pdf} download='SubletAgreement.pdf'>Download PDF</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <KWFooter></KWFooter>
            </div>
        </>
    )
}